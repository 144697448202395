export const DATE_FORMAT = "YYYY-MM-DD";
export const MONTH_FORMAT = "YYYY-MM";
export const GRAPH_AXIS_DATE_FORMAT = "DD-MMMM-YY";

export const getDaysBetweenDates = (startDate, endDate) => {
  var now = startDate.clone(),
    dates: any[] = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(DATE_FORMAT));
    now.add(1, "days");
  }
  return dates;
};

export const getMonthsBetweenDates = (startDate, endDate) => {
  var now = startDate.clone(),
    dates: any[] = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(MONTH_FORMAT));
    now.add(1, "months");
  }
  return dates;
};
