import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StatValue = ({
  withLink,
  link,
  text,
  customClass,
  isButton,
  onClick,
}: {
  withLink: boolean;
  link?: string;
  text: string;
  customClass?: any;
  isButton: boolean;
  onClick?: () => void;
}) => {
  if (withLink && link) {
    return (
      <Link target="_blank" to={link} style={{ color: customClass ? "" : "#000000de" }}>
        <Typography variant="body2" className={customClass}>
          {text}
        </Typography>
      </Link>
    );
  }

  if (isButton && onClick) {
    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    };

    return (
      <Typography
        onClick={handleClick}
        variant="body2"
        className={customClass}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" className={customClass}>
      {text}
    </Typography>
  );
};

export default StatValue;
