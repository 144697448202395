import { Box, Button, ButtonProps, Tooltip, Typography, colors } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridDataGroupNode,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import React from "react";
import { DATE_FORMAT, getDaysBetweenDates } from "../../utils/dates";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { isStaging } from "../../utils/env";
import HelpIcon from "@mui/icons-material/Help";
import { makeStyles } from "@mui/styles";
import StatValue from "../../components/StatValue";
import { formatNumbersWithCommas, getClickSearchesPercentage, getRevenueClickRatio } from "../../utils/helpers";
import { CommonVariableNames } from "../PublisherChannelStats/types";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "20px",
  paddingRight: "20px",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 500,
  textAlign: "center",
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    textAlign: "center",
    width: "100%",
  },
}));

const LinesIcon = styled("img")(({ theme }) => ({}));
const Lines2Icon = styled("img")(({ theme }) => ({
  width: "50%",
}));

const useStyles = makeStyles({
  colBackground: {
    background: isStaging() ? colors.lightGreen[50] : colors.grey[100],
  },
  rowBackground: {
    background: isStaging() ? colors.lightGreen[50] : colors.grey[300],
  },
  expandedRowBackground: {
    background: colors.grey[200],
    borderRight: `1px solid`,
    borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
  },
  expandedFurtherRowBackground: {
    background: colors.grey[300],
    borderRight: `1px solid`,
    borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
  },
  headerBackground: {
    background: isStaging() ? colors.lightGreen[50] : "#ffffff",
  },
  rpm: {
    color: "#0277bd",
  },
  revenue: {
    color: "#228b22",
  },
  cellBorders: {
    borderRight: `1px solid`,
    borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
  },
  selectedCell: {
    backgroundColor: colors.red[50],
    borderRight: `1px solid`,
    borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
  },
});

const WrapperLabel = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const mockData = [
  {
    id: 1,
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    wrapperId: "wrapper1",
    channelId: "",
    geo: "",
    hierarchy: ["wrapper1"],
    channelCount: 2,
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
  },
  {
    id: 2,
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    wrapperId: "wrapper1",
    channelId: "channel1",
    geo: "",
    hierarchy: ["wrapper1", "wrapper1::channel1"],
    geoCount: 2,
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
  },
  {
    id: 3,
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    wrapperId: "wrapper1",
    channelId: "channel1",
    geo: "US",
    hierarchy: ["wrapper1", "wrapper1::channel1", "wrapper1::channel1::US"],
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
  },
  {
    id: 4,
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    wrapperId: "wrapper1",
    channelId: "channel1",
    geo: "UK",
    hierarchy: ["wrapper1", "wrapper1::channel1", "wrapper1::channel1::UK"],
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
  },
  {
    id: 5,
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    wrapperId: "wrapper1",
    channelId: "channel2",
    geo: "",
    hierarchy: ["wrapper1", "wrapper1::channel2"],
    geoCount: 1,
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
  },
  {
    id: 6,
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    wrapperId: "wrapper1",
    channelId: "channel2",
    geo: "US",
    hierarchy: ["wrapper1", "wrapper1::channel2", "wrapper1::channel2::US"],
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
  },
  {
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
    hierarchy: ["wrapper2"],
    geography: "US",
    wrapperId: "wrapper2",
    channelId: "",
    id: 7,
    channelCount: 1,
  },
  {
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
    hierarchy: ["wrapper2", "wrapper2::channel"],
    geography: "US",
    wrapperId: "wrapper2",
    channelId: "channel",
    id: 8,
    geoCount: 1,
  },
  {
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
    hierarchy: ["wrapper2", "wrapper2::channel", "wrapper2::channel::US"],
    geography: "US",
    wrapperId: "wrapper2",
    channelId: "channel",
    id: 9,
    geo: "US",
  },
  {
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
    hierarchy: ["wrapper3"],
    geography: "US",
    wrapperId: "wrapper3",
    channelId: "",
    id: 1217,
    channelCount: 1,
  },
  {
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
    hierarchy: ["wrapper3", "wrapper3::channel"],
    geography: "US",
    wrapperId: "wrapper3",
    channelId: "channel",
    id: 2118,
    geoCount: 1,
  },
  {
    "2023-09-01": {
      Clicks: 58,
      "Monetized Searches": 2518,
      "Total Searches": 3767,
      Revenue: "17.51",
      RPM: "6.95",
    },
    "2023-09-02": {
      Clicks: 86,
      "Monetized Searches": 3797,
      "Total Searches": 5563,
      Revenue: "23.67",
      RPM: "6.23",
    },
    "2023-09-03": {
      Clicks: 87,
      "Monetized Searches": 3860,
      "Total Searches": 5597,
      Revenue: "16.07",
      RPM: "4.16",
    },
    "2023-09-04": {
      Clicks: 68,
      "Monetized Searches": 2882,
      "Total Searches": 4357,
      Revenue: "10.32",
      RPM: "3.58",
    },
    "2023-09-05": {
      Clicks: 64,
      "Monetized Searches": 2774,
      "Total Searches": 4106,
      Revenue: "8.11",
      RPM: "2.92",
    },
    "2023-09-06": {
      Clicks: 63,
      "Monetized Searches": 2901,
      "Total Searches": 4041,
      Revenue: "8.78",
      RPM: "3.03",
    },
    "2023-09-07": {
      Clicks: 62,
      "Monetized Searches": 2765,
      "Total Searches": 3968,
      Revenue: "14.03",
      RPM: "5.07",
    },
    "2023-09-08": {
      Clicks: 61,
      "Monetized Searches": 2722,
      "Total Searches": 3947,
      Revenue: "12.19",
      RPM: "4.48",
    },
    "2023-09-09": {
      Clicks: 61,
      "Monetized Searches": 2671,
      "Total Searches": 3954,
      Revenue: "7.00",
      RPM: "2.62",
    },
    "2023-09-10": {
      Clicks: 60,
      "Monetized Searches": 2586,
      "Total Searches": 3867,
      Revenue: "12.72",
      RPM: "4.92",
    },
    "2023-09-11": {
      Clicks: 56,
      "Monetized Searches": 2459,
      "Total Searches": 3580,
      Revenue: "7.26",
      RPM: "2.95",
    },
    "2023-09-12": {
      Clicks: 54,
      "Monetized Searches": 2670,
      "Total Searches": 3479,
      Revenue: "15.76",
      RPM: "5.90",
    },
    "2023-09-13": {
      Clicks: 75,
      "Monetized Searches": 3158,
      "Total Searches": 4802,
      Revenue: "24.21",
      RPM: "7.67",
    },
    "2023-09-14": {
      Clicks: 51,
      "Monetized Searches": 2538,
      "Total Searches": 3277,
      Revenue: "13.22",
      RPM: "5.21",
    },
    "2023-09-15": {
      Clicks: 81,
      "Monetized Searches": 3674,
      "Total Searches": 5237,
      Revenue: "43.09",
      RPM: "11.73",
    },
    "2023-09-16": {
      Clicks: 80,
      "Monetized Searches": 3873,
      "Total Searches": 5145,
      Revenue: "38.45",
      RPM: "9.93",
    },
    "2023-09-17": {
      Clicks: 79,
      "Monetized Searches": 3743,
      "Total Searches": 5071,
      Revenue: "39.12",
      RPM: "10.45",
    },
    "2023-09-18": {
      Clicks: 62,
      "Monetized Searches": 2823,
      "Total Searches": 4024,
      Revenue: "27.45",
      RPM: "9.72",
    },
    "2023-09-19": {
      Clicks: 55,
      "Monetized Searches": 2492,
      "Total Searches": 3559,
      Revenue: "24.48",
      RPM: "9.82",
    },
    total: {
      totalRevenue: "363.44",
      totalRpm: "6.39",
      totalClicks: 1263,
      totalMonetizedSearches: 56906,
      totalOfTotalSearches: 81341,
    },
    hierarchy: ["wrapper3", "wrapper3::channel", "wrapper3::channel::US"],
    geography: "US",
    wrapperId: "wrapper3",
    channelId: "channel",
    id: 12139,
    geo: "US",
  },
];

const mockOverallRow = {
  wrapperId: "Overall",
  total: {
    totalMonetizedSearches: 2466975,
    totalOfTotalSearches: 6080529,
    totalRpm: "23.42",
    totalRevenue: "57767.70",
    totalClicks: 263858,
  },
  visibility: {
    detailedView: false,
  },
  "2023-09-01": {
    "Monetized Searches": 142783,
    "Total Searches": 373261,
    RPM: "27.30",
    Revenue: "3897.67",
    Clicks: 14041,
  },
  "2023-09-02": {
    "Monetized Searches": 141845,
    "Total Searches": 362759,
    RPM: "24.91",
    Revenue: "3533.37",
    Clicks: 14052,
  },
  "2023-09-03": {
    "Monetized Searches": 143527,
    "Total Searches": 354708,
    RPM: "24.95",
    Revenue: "3580.46",
    Clicks: 17055,
  },
  "2023-09-04": {
    "Monetized Searches": 141346,
    "Total Searches": 359470,
    RPM: "24.98",
    Revenue: "3531.36",
    Clicks: 16438,
  },
  "2023-09-05": {
    "Monetized Searches": 141693,
    "Total Searches": 363128,
    RPM: "24.05",
    Revenue: "3407.30",
    Clicks: 16212,
  },
  "2023-09-06": {
    "Monetized Searches": 147018,
    "Total Searches": 367731,
    RPM: "23.53",
    Revenue: "3458.99",
    Clicks: 17062,
  },
  "2023-09-07": {
    "Monetized Searches": 139333,
    "Total Searches": 369593,
    RPM: "23.70",
    Revenue: "3301.71",
    Clicks: 15175,
  },
  "2023-09-08": {
    "Monetized Searches": 150633,
    "Total Searches": 353448,
    RPM: "23.49",
    Revenue: "3537.92",
    Clicks: 17066,
  },
  "2023-09-09": {
    "Monetized Searches": 147767,
    "Total Searches": 354113,
    RPM: "22.14",
    Revenue: "3271.27",
    Clicks: 16763,
  },
  "2023-09-10": {
    "Monetized Searches": 141510,
    "Total Searches": 343984,
    RPM: "22.96",
    Revenue: "3249.36",
    Clicks: 15360,
  },
  "2023-09-11": {
    "Monetized Searches": 133514,
    "Total Searches": 330315,
    RPM: "20.99",
    Revenue: "2802.25",
    Clicks: 11838,
  },
  "2023-09-12": {
    "Monetized Searches": 139119,
    "Total Searches": 312772,
    RPM: "20.12",
    Revenue: "2798.54",
    Clicks: 12188,
  },
  "2023-09-13": {
    "Monetized Searches": 88336,
    "Total Searches": 265266,
    RPM: "25.33",
    Revenue: "2237.52",
    Clicks: 8036,
  },
  "2023-09-14": {
    "Monetized Searches": 104795,
    "Total Searches": 274072,
    RPM: "20.00",
    Revenue: "2095.85",
    Clicks: 10854,
  },
  "2023-09-15": {
    "Monetized Searches": 137487,
    "Total Searches": 310290,
    RPM: "22.13",
    Revenue: "3043.25",
    Clicks: 14753,
  },
  "2023-09-16": {
    "Monetized Searches": 135206,
    "Total Searches": 313339,
    RPM: "21.33",
    Revenue: "2883.51",
    Clicks: 15485,
  },
  "2023-09-17": {
    "Monetized Searches": 102964,
    "Total Searches": 247617,
    RPM: "25.84",
    Revenue: "2660.68",
    Clicks: 11566,
  },
  "2023-09-18": {
    "Monetized Searches": 101430,
    "Total Searches": 237589,
    RPM: "22.98",
    Revenue: "2331.26",
    Clicks: 11043,
  },
  "2023-09-19": {
    "Monetized Searches": 86669,
    "Total Searches": 187074,
    RPM: "24.75",
    Revenue: "2145.43",
    Clicks: 8871,
  },
  channelId: "",
  hierarchy: ["Overall"],
  id: 91,
};

export default function WrapperChannelStats() {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(
    moment("01-09-2023", "DD-MM-YYYY").startOf("month").format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = React.useState(moment("01-09-2023", "DD-MM-YYYY").endOf("month").format(DATE_FORMAT));
  const newDatesBetweenStartAndEnd = getDaysBetweenDates(moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT));

  console.log("newDatesBetweenStartAndEnd", newDatesBetweenStartAndEnd);

  const renderWrapperIdCell = (params: GridRenderCellParams) => {
    if (params.row.hierarchy.length > 1) {
      return null;
    }

    return (
      <Box>
        <WrapperLabel>{params.row.wrapperId}</WrapperLabel>
      </Box>
    );
  };

  const renderLabelCell = (params: GridRenderCellParams) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        {isStaging() && (
          <Box display="flex" alignItems="center">
            <Tooltip
              enterTouchDelay={0}
              arrow
              title={
                <Box>
                  <ul style={{ paddingLeft: "15px" }}>
                    <li>
                      <Typography variant="body2">
                        BEM Gross = search queries identified by Big Engage system
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Redirected = invalid searches detected & not sent to feed</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Feed Gross = queries received by search feed</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Max Cap = daily cap for feed</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        Monetized = potential revenue generating queries identified by feed
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Clicks = clicks on ads</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">RPM = revenue per 1000</Typography>
                    </li>
                    <li>
                      <Typography variant="body2">Revenue = amount earned</Typography>
                    </li>
                  </ul>
                </Box>
              }
              placement="left-start"
            >
              <HelpIcon sx={{ width: 20, height: 20, marginRight: 0.5 }} />
            </Tooltip>
            <Typography variant="body2">BEM Gross</Typography>
          </Box>
        )}
        {isStaging() && <Typography variant="body2">Redirected</Typography>}
        <Typography variant="body2">{isStaging() ? "Feed Gross" : "Gross"}</Typography>
        {isStaging() && <Typography variant="body2">Max Cap</Typography>}
        <Typography variant="body2">Monetized</Typography>
        <Typography variant="body2">Clicks</Typography>
        <Typography variant="body2" className={classes.rpm}>
          RPM
        </Typography>
        <Typography variant="body2" className={classes.revenue}>
          Revenue
        </Typography>
        {/* {row.visibility.detailedView && ( */}
        <>
          <Typography variant="body2">Click/Searches</Typography>
          <Typography variant="body2">Revenue/Click</Typography>
        </>
        {/* )} */}
      </Box>
    );
  };

  const renderDateCell = (params: GridRenderCellParams) => {
    const { row, colDef } = params;

    if (row[colDef.field]) {
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {isStaging() && <Typography variant="body2">0</Typography>}
          {isStaging() && (
            <Typography variant="body2">
              <a target="__blank" href="https://google.com">
                0
              </a>
            </Typography>
          )}
          <StatValue
            text={formatNumbersWithCommas(row[colDef.field][CommonVariableNames.TotalSearches])}
            isButton
            withLink={false}
            // onClick={isGraphVisible ? () => handleClickStat(colDef.field, GraphTypes.Raw) : undefined}
          />
          {isStaging() && <Typography variant="body2">0</Typography>}
          <StatValue
            text={formatNumbersWithCommas(row[colDef.field][CommonVariableNames.MonetizedSearches])}
            isButton
            withLink={false}
            // onClick={() => handleClickStat(colDef.field, GraphTypes.Monetized)}
          />
          <Typography variant="body2">{formatNumbersWithCommas(row[colDef.field].Clicks)}</Typography>
          <Typography variant="body2" className={classes.rpm}>
            ${formatNumbersWithCommas(row[colDef.field].RPM)}
            {row[colDef.field][CommonVariableNames.MonetizedSearches] === 0 ? "*" : ""}
          </Typography>
          <StatValue
            text={`$${formatNumbersWithCommas(row[colDef.field].Revenue)}`}
            isButton
            withLink={false}
            // onClick={() => handleClickStat(colDef.field, GraphTypes.Rev)}
            customClass={classes.revenue}
          />
          {/* {row.visibility.detailedView && ( */}
          <>
            <Typography variant="body2">
              {getClickSearchesPercentage(
                row[colDef.field].Clicks,
                row[colDef.field][CommonVariableNames.MonetizedSearches] ||
                  row[colDef.field][CommonVariableNames.TotalSearches]
              )}
              %{row[colDef.field][CommonVariableNames.MonetizedSearches] === 0 ? "*" : ""}
            </Typography>
            <Typography variant="body2">
              ${getRevenueClickRatio(Number(row[colDef.field].Revenue), row[colDef.field].Clicks)}
            </Typography>
          </>
          {/* )} */}
        </Box>
      );
    }
  };

  const renderTotalCell = (params: GridRenderCellParams) => {
    const { row } = params;
    if (row.total !== undefined) {
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {isStaging() && <Typography variant="body2">0</Typography>}
          {/* Link is placeholder for now */}
          {isStaging() && (
            <a target="__blank" href="https://google.com">
              0
            </a>
          )}
          <Typography variant="body2">{formatNumbersWithCommas(row.total.totalOfTotalSearches)}</Typography>
          {isStaging() && <Typography variant="body2">0</Typography>}
          <Typography variant="body2">{formatNumbersWithCommas(row.total.totalMonetizedSearches)}</Typography>
          <Typography variant="body2">{formatNumbersWithCommas(row.total.totalClicks)}</Typography>
          <Typography variant="body2" className={classes.rpm}>
            ${formatNumbersWithCommas(row.total.totalRpm)}
            {row.total.totalMonetizedSearches === 0 ? "*" : ""}
          </Typography>
          <Typography variant="body2" className={classes.revenue}>
            ${formatNumbersWithCommas(row.total.totalRevenue)}
          </Typography>
          <>
            <Typography variant="body2">
              {getClickSearchesPercentage(row.total.totalClicks, row.total.totalMonetizedSearches)}%
            </Typography>
            <Typography variant="body2">
              ${getRevenueClickRatio(Number(row.total.totalRevenue), row.total.totalClicks)}
            </Typography>
          </>
        </Box>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "wrapperId",
      headerName: "Wrapper Id",
      width: 150,
      align: "left",
      headerAlign: "left",
      sortable: false,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: renderWrapperIdCell,
      cellClassName: classes.colBackground,
      headerClassName: classes.headerBackground,
    },
    {
      field: "labels",
      headerName: "",
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: renderLabelCell,
      cellClassName: classes.colBackground,
      align: "right",
      disableReorder: true,
      headerClassName: classes.headerBackground,
    },
    ...newDatesBetweenStartAndEnd.map(
      (dateString): GridColDef => ({
        field: dateString,
        headerName: moment(dateString, DATE_FORMAT).format("MM-DD-YY"),
        width: 80,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        renderCell: renderDateCell,
        align: "right",
        headerAlign: "right",
        resizable: false,
        disableReorder: true,
      })
    ),
    {
      field: "total",
      headerName: "Total",
      width: 100,
      renderCell: renderTotalCell,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: "right",
      headerAlign: "right",
      cellClassName: classes.colBackground,
      headerClassName: classes.headerBackground,
    },
  ];

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy;

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "Sub-channel / Geo",
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
    align: "center",
    headerAlign: "center",
    width: 160,
    resizable: false,
  };

  function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode, row } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps["onClick"] = (event) => {
      if (rowNode.type !== "group") {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box>
        <div>
          {filteredDescendantCount > 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              // sx={{ marginLeft: row.hierarchy.length == 2 ? "5px" : "-30px" }}
            >
              <Typography fontWeight={500} textAlign="center">
                {row.channelId}
              </Typography>
              <Button onClick={handleClick} tabIndex={-1} size="small" sx={{ color: "black", textTransform: "none" }}>
                {(rowNode as GridDataGroupNode).childrenExpanded ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                {row.hierarchy.length === 1 ? "Channels " : row.hierarchy.length == 2 ? "Geos " : ""}(
                {row.hierarchy.length === 1 ? row.channelCount : filteredDescendantCount})
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography fontWeight={500} textAlign="center">
                {row.geo}
              </Typography>
            </Box>
          )}
        </div>
      </Box>
    );
  }

  const getRowClassName = (params) => {
    if (params.row.wrapperId === "Overall") {
      return classes.colBackground;
    } else {
      return "";
    }
  };

  const getCellClassName = (params) => {
    if (params.row.hierarchy.length == 3) {
      return classes.expandedFurtherRowBackground;
    }

    if (params.row.hierarchy.length == 2) {
      return classes.expandedRowBackground;
    }

    if (params.field !== "channel" && params.field !== "labels" && params.field !== "total") {
      return classes.cellBorders;
    }

    return "";
  };

  const GridToolbar = () => {
    return (
      <Box padding="10px">
        <HeaderBox>
          <Title>Wrapper Channel Stats ({moment(startDate, DATE_FORMAT).format("MMMM YYYY")})</Title>
        </HeaderBox>
      </Box>
    );
  };

  const baseRowHeight = isStaging() ? 220 : 160;
  return (
    <Box maxHeight="100vh">
      <DataGridPro
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: colors.lightBlue[50],
          },
          "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1px solid`,
            borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
          },
          background: isStaging() ? colors.lightGreen[50] : "#ffffff",
          maxHeight: "100vh",
        }}
        treeData
        pinnedRows={{ bottom: [mockOverallRow] }}
        columns={columns}
        rows={mockData}
        rowHeight={baseRowHeight}
        getTreeDataPath={getTreeDataPath}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        groupingColDef={groupingColDef}
        initialState={{
          pinnedColumns: { left: ["wrapperId", "labels"], right: ["total"] },
        }}
        hideFooter
        slots={{
          toolbar: () => <GridToolbar />,
        }}
        rowSelection={false}
      />
    </Box>
  );
}
