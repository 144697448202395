import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";

type LinkTooltipProps = {
  url: string;
};

const CopyButton = styled(Button)({
  textTransform: "none",
  color: "#ffffff",
  marginTop: "10px",
  backgroundColor: "#6ab793",
  "&:hover": {
    backgroundColor: "#6ab793",
  },
});

const LinkButton = styled(Button)({
  textTransform: "none",
  margin: 0,
  padding: 0,
  backgroundColor: "none",
  minWidth: "unset",
  "&:hover": {
    backgroundColor: "none",
  },
});

const useStyles = makeStyles(() => ({
  arrow: {
    "&:before": {
      color: "#5b9c7d",
    },
  },
  copyButton: {
    background: "#6ab793",
    color: "white",
  },
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => {
  const classes = useStyles();
  return <Tooltip {...props} classes={{ popper: className, arrow: classes.arrow }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#5b9c7d",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "none",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const LinkTooltip = ({ url }: LinkTooltipProps) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const onCloseTooltip = () => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 100);
    }
  };

  return (
    <CustomTooltip
      onClose={onCloseTooltip}
      arrow
      placement="right-start"
      title={
        <Box padding="10px" display="flex" flexDirection="column" alignItems="flex-end">
          <Typography color="#ffffff" variant="body2">
            {url}
          </Typography>
          <CopyButton
            onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(url);
              setIsCopied(true);
            }}
            size="small"
          >
            {isCopied ? "Copied" : "Copy"}
          </CopyButton>
        </Box>
      }
    >
      <LinkButton size="small">Link</LinkButton>
    </CustomTooltip>
  );
};

export default LinkTooltip;
