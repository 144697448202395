import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { DATE_FORMAT, GRAPH_AXIS_DATE_FORMAT, getDaysBetweenDates } from "../../utils/dates";
import { useParams } from "react-router-dom";
import { GraphTypes } from "./types";
import { getMarketingData } from "../../api";
import { CommonVariableNames, DataPoint } from "./types";
import { getStatLabel } from "../../utils/helpers";
import LineChart from "../../components/LineChart";
import { XAxisType } from "../../shared/types/lineChart";
import CapitalizedLabel from "../../components/CapitalizedLabel";
import { debounce } from "lodash";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 500,
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    width: "100%",
  },
}));

const TitleLineBreak = styled("br")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  paddingLeft: "20px",
  paddingRight: "20px",
  height: "70px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "15px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const SwitchAndGraphTypeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "end",
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const ChartBox = styled(Box)(({ theme }) => ({
  margintTop: 0,
  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "50px",
  },
}));

const defaultGraphType = GraphTypes.Raw;
const noOfDaysToSubtract = 30;

type PublisherStatsGraphProps = {
  date?: string;
  graphType?: string;
  toggleGraphVisibility: (value: boolean) => void;
  isGraphVisible: boolean;
};

export default function PublisherStatsGraph({
  date,
  graphType,
  toggleGraphVisibility,
  isGraphVisible,
}: PublisherStatsGraphProps) {
  const startDateMomentObj = moment();
  const [period, setPeriod] = React.useState({
    startDate: startDateMomentObj.subtract(noOfDaysToSubtract, "days").format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [graphData, setGraphData] = React.useState({});
  const [selectedGraphType, setSelectedGraphType] = React.useState<string>(defaultGraphType);
  const urlParams = useParams<{ publisherName: string; secret: string }>();
  const secret = urlParams.secret || "";
  const publisherName = urlParams.publisherName || "";
  const [error, setError] = React.useState(false);
  const didMount = React.useRef(false);
  const [loadedFirstTime, setLoadedFirstTime] = React.useState(false);
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    if (!didMount.current) {
      if (date && graphType) {
        const startDate = moment(date, DATE_FORMAT).subtract(noOfDaysToSubtract, "days").format(DATE_FORMAT);
        const endDate = moment(date, DATE_FORMAT).format(DATE_FORMAT);
        setPeriod({ startDate, endDate });
        setSelectedGraphType(graphType);
        getPublisherStatsAsync(startDate, endDate, true);
      } else {
        getPublisherStatsAsync(period.startDate, period.endDate, true);
      }

      didMount.current = true;
    }
  }, []);

  React.useEffect(() => {
    if (didMount.current && date && graphType && loadedFirstTime) {
      const startDate = moment(date, DATE_FORMAT).subtract(noOfDaysToSubtract, "days").format(DATE_FORMAT);
      const endDate = moment(date, DATE_FORMAT).format(DATE_FORMAT);
      setPeriod({ startDate, endDate });
      setSelectedGraphType(graphType);
      getPublisherStatsAsync(startDate, endDate, false);
    }
  }, [date, graphType]);

  React.useLayoutEffect(() => {
    const updateSize = debounce(() => {
      setWindowHeight(window.innerHeight);
    }, 500);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getPublisherStatsAsync = async (startDate: string, endDate: string, loadingFirstTime: boolean) => {
    try {
      setIsLoading(true);
      const { data } = await getMarketingData(startDate, endDate, publisherName, secret);
      const formattedData = await formatDataForLineChart(data, startDate, endDate);
      setGraphData(formattedData as any);
      setIsLoading(false);
      if (loadingFirstTime) {
        setLoadedFirstTime(true);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(true);
    }
  };

  const formatDataForLineChart = (data: DataPoint[], startDate: string, endDate: string) => {
    return new Promise((resolve) => {
      const datesBetweenStartAndEnd = getDaysBetweenDates(moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT));

      // Finding all channel names
      const allChannels: string[] = [];
      data.forEach((dataPoint) => {
        if (dataPoint.Channel.length !== 0 && allChannels.indexOf(dataPoint.Channel) === -1) {
          allChannels.push(dataPoint.Channel);
        }
      });

      allChannels.sort((a, b) => {
        const nameA = isNaN(Number(a)) ? a : Number(a);
        const nameB = isNaN(Number(b)) ? b : Number(b);

        if (typeof nameA === "number" && typeof nameB === "number") {
          return nameA - nameB;
        }

        if (typeof nameA === "number") return -1; // Put numbers before strings
        if (typeof nameB === "number") return 1;

        return nameA.localeCompare(nameB);
      });

      const revDatasets: any[] = [];
      const rawDatasets: any[] = [];
      const capDatasets: any[] = [];
      const monetizedDatasets: any[] = [];

      allChannels.forEach((channel) => {
        let revValues: any[] = [];
        let rawValues: any[] = [];
        let monetizedValues: any[] = [];
        let capValues: any[] = [];

        datesBetweenStartAndEnd.forEach((date) => {
          const recordsWithDate = data.filter((dataPoint) => dataPoint.Date === date && dataPoint.Channel === channel);
          let yRevValue = 0;
          let yRawValue = 0;
          let yMonetizedValue = 0;

          recordsWithDate.forEach((record) => {
            yRevValue += Number(record[CommonVariableNames.Revenue]);
            yRawValue += Number(record[CommonVariableNames.TotalSearches]);
            yMonetizedValue += Number(record[CommonVariableNames.MonetizedSearches]);
          });

          revValues.push({ x: date, y: yRevValue });
          rawValues.push({ x: date, y: yRawValue });
          monetizedValues.push({ x: date, y: yMonetizedValue });
        });

        revDatasets.push({
          label: channel,
          data: revValues,
        });

        rawDatasets.push({
          label: channel,
          data: rawValues,
        });

        capDatasets.push({
          label: channel,
          data: capValues,
        });
        monetizedDatasets.push({
          label: channel,
          data: monetizedValues,
        });
      });

      resolve({
        [GraphTypes.Rev]: revDatasets,
        [GraphTypes.Raw]: rawDatasets,
        [GraphTypes.Cap]: capDatasets,
        [GraphTypes.Monetized]: monetizedDatasets,
      });
    });
  };

  const handleChangeGraphType = (event: SelectChangeEvent) => {
    setSelectedGraphType(event.target.value as string);
  };

  if (isLoading) {
    return (
      <Box height="60vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress />
        <Typography marginTop={1}>Loading Chart</Typography>
      </Box>
    );
  }

  if (error) {
    return <></>;
  }

  return (
    <Box>
      <HeaderBox>
        <Title>
          <CapitalizedLabel>{publisherName}</CapitalizedLabel> last 31 days {getStatLabel(selectedGraphType)}{" "}
          <TitleLineBreak /> ({moment(period.startDate, DATE_FORMAT).format(GRAPH_AXIS_DATE_FORMAT)} to{" "}
          {moment(period.endDate, DATE_FORMAT).format(GRAPH_AXIS_DATE_FORMAT)})
        </Title>
        <SwitchAndGraphTypeBox>
          <FormControlLabel
            sx={{ marginRight: 2 }}
            control={
              <Switch
                size="small"
                checked={isGraphVisible}
                onChange={() => toggleGraphVisibility(!isGraphVisible)}
                color="warning"
              />
            }
            label="Chart"
            labelPlacement="start"
          />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel size="small" id="select-label">
              Graph Type
            </InputLabel>
            <Select
              size="small"
              labelId="select-label"
              value={selectedGraphType}
              label="Graph Type"
              onChange={handleChangeGraphType}
            >
              <MenuItem value={GraphTypes.Raw}>Gross Searches</MenuItem>
              <MenuItem value={GraphTypes.Rev}>Revenue</MenuItem>
              <MenuItem value={GraphTypes.Monetized}>Monetized Searches</MenuItem>
            </Select>
          </FormControl>
        </SwitchAndGraphTypeBox>
      </HeaderBox>
      <ChartBox>
        <LineChart
          isCurrency={selectedGraphType === GraphTypes.Rev}
          height={windowHeight / 1.65 - 70}
          data={graphData[selectedGraphType]}
          onClickLegendEnabled={true}
          xAxisType={XAxisType.Day}
        />
      </ChartBox>
    </Box>
  );
}
