import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import React from "react";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Box, Button } from "@mui/material";
import moment from "moment";
import { DATE_FORMAT, MONTH_FORMAT } from "../../utils/dates";
import { formatNumbersWithCommas } from "../../utils/helpers";
import { XAxisType } from "../../shared/types/lineChart";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";

const ChartBox = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  paddingTop: "10px",
  boxSizing: "border-box",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors);

type LineChartProps = {
  data: any[];
  height: number;
  isCurrency: boolean;
  onClickLegendEnabled: boolean;
  xAxisType: XAxisType;
  isLegendsButtonsForPubs?: boolean;
  getPublisherLink?: (publisherName: string) => string;
};

const xAxisTypeConfigs = {
  [XAxisType.Month]: {
    type: "time",
    time: {
      unit: "month",
      displayFormats: {
        day: MONTH_FORMAT,
      },
    },
  },
  [XAxisType.Day]: {
    type: "time",
    time: {
      unit: "day",
      displayFormats: {
        day: "MM-DD-YY",
      },
    },
  },
};

export default function LineChart({
  data,
  height,
  isCurrency,
  onClickLegendEnabled,
  xAxisType,
  isLegendsButtonsForPubs = false,
  getPublisherLink,
}: LineChartProps) {
  const [tooltip, setTooltip] = React.useState({
    isVisible: false,
    text: "",
    x: 0,
    y: 0,
    isMouseOnTooltip: false,
    backgroundColor: "",
  });
  const timeoutRef = React.useRef<any>();
  const didMount = React.useRef(false);

  const windowOnClickEvent = () => {
    setTooltip({ ...tooltip, isVisible: false });
  };

  React.useEffect(() => {
    if (!didMount.current) {
      window.addEventListener("click", windowOnClickEvent);
      didMount.current = true;
    }
  }, []);

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: xAxisTypeConfigs[xAxisType],
      y: {
        ticks: {
          callback: function (value) {
            return (isCurrency ? "$" : "") + formatNumbersWithCommas(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return moment(context[0].raw.x, DATE_FORMAT).format("MMM DD, YYYY");
          },
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += (isCurrency ? "$" : "") + formatNumbersWithCommas(context.parsed.y);
            }
            return label;
          },
        },
      },
      colors: {
        forceOverride: true,
      },
      legend: onClickLegendEnabled
        ? {
            onHover: isLegendsButtonsForPubs
              ? (event, legendItem, legend) => {
                  if (tooltip.isVisible && tooltip.text === legendItem.text) {
                    return;
                  }
                  const { row } = legend.legendHitBoxes[legendItem.datasetIndex];

                  clearTimeout(timeoutRef.current);
                  const yExtraPixelsAccordingToLegendRow = Number(row) * 2 * 10 + Number(row) * 2;
                  setTooltip({
                    ...tooltip,
                    x: event.x,
                    y: yExtraPixelsAccordingToLegendRow - 15,
                    text: legendItem.text,
                    isVisible: true,
                    backgroundColor: legendItem.strokeStyle,
                  });
                }
              : null,
            onLeave: isLegendsButtonsForPubs
              ? () => {
                  clearTimeout(timeoutRef.current);
                  timeoutRef.current = setTimeout(() => {
                    setTooltip({ ...tooltip, isVisible: false });
                  }, 2000);
                }
              : null,
          }
        : {
            onClick: null,
          },
    },
  };

  return (
    <ChartBox height={height}>
      {isLegendsButtonsForPubs && getPublisherLink && getPublisherLink(tooltip.text) && (
        <Box
          onMouseOver={() => {
            clearTimeout(timeoutRef.current);
          }}
          onMouseLeave={() => setTooltip({ ...tooltip, isVisible: false, isMouseOnTooltip: false })}
          sx={{
            position: "absolute",
            top: tooltip.y,
            left: tooltip.x,
            opacity: tooltip.isVisible ? 1 : 0,
            background: tooltip.backgroundColor,
            borderRadius: 1,
          }}
        >
          <Button href={getPublisherLink(tooltip.text)} size="small" target="_blank" sx={{ color: "#ffffff" }}>
            <span>{tooltip.text}</span>
            <ArrowForwardIcon fontSize="small" sx={{ marginLeft: "5px" }} />
          </Button>
        </Box>
      )}
      <Line options={options as any} data={{ datasets: data }} />
    </ChartBox>
  );
}
