import { createContext, useState, useEffect, useMemo, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";

interface ISnackBarContext {
  addAlert: (alert: any) => void;
}

const AUTO_DISMISS = 4000;

export const SnackBarContext = createContext<ISnackBarContext>({
  addAlert: () => {},
});

interface SnackBarProviderProps {
  children?: React.ReactNode;
}

export function SnackBarProvider({ children }: SnackBarProviderProps) {
  const [alerts, setAlerts] = useState<any[]>([]);

  const activeAlertIds = alerts.join(",");
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(() => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)), AUTO_DISMISS);
      return () => clearTimeout(timer);
    }
  }, [activeAlertIds]);

  const addAlert = useCallback((content: any) => {
    setAlerts((alerts) => [content, ...alerts]);
  }, []);

  const value = useMemo(() => ({ addAlert }), [addAlert]);

  return (
    <SnackBarContext.Provider value={value}>
      <>
        {children}
        {alerts.map((alert, index) => (
          <Snackbar
            key={index}
            open={true}
            message={alert}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          />
        ))}
      </>
    </SnackBarContext.Provider>
  );
}
