import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  ButtonProps,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { DATE_FORMAT, getDaysBetweenDates } from "../../utils/dates";
import moment from "moment";
import { DataPoint, CommonVariableNames, StatsVisibility } from "./types";
import { calculateRpm, formatNumTo2Decimals } from "../../utils/helpers";
import { getMarketingData } from "../../api";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridDataGroupNode,
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
  gridClasses,
} from "@mui/x-data-grid-pro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LinkTooltip from "../../components/CustomTooltip";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import { colors } from "@mui/material";
import { DataGridState } from "../../shared/types/datagrid";
import PublisherStatsGraph from "./PublisherStatsGraph";
import { formatNumbersWithCommas } from "../../utils/helpers";
import { isStaging } from "../../utils/env";
import Papa from "papaparse";
import StatValue from "../../components/StatValue";
import { GraphTypes } from "./types";
import Header from "../../components/Header";
import HelpIcon from "@mui/icons-material/Help";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 500,
  textAlign: "center",
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    textAlign: "center",
    width: "100%",
  },
}));

const useStyles = makeStyles({
  colBackground: {
    background: isStaging() ? colors.lightGreen[50] : colors.grey[100],
  },
  headerBackground: {
    background: isStaging() ? colors.lightGreen[50] : "#ffffff",
  },
  rpm: {
    color: "#0277bd",
  },
  revenue: {
    color: "#228b22",
  },
  cellBorders: {
    borderRight: `1px solid`,
    borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
  },
  selectedCell: {
    backgroundColor: colors.red[50],
    borderRight: `1px solid`,
    borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
  },
});

const ChannelLabel = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

const LifeTimeEarnings = styled(Typography)(({ theme }) => ({
  width: "35%",
  color: "#228b22",
  textAlign: "end",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const StagingLabel = styled(Typography)(({ theme }) => ({
  width: "35%",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "20px",
  paddingRight: "20px",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

const HeaderBoxMobile = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "12px",
  marginBottom: "10px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const LifeTimeEarningsMobile = styled(Typography)(({ theme }) => ({
  color: "#228b22",
  textAlign: "end",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  fontSize: "14px",
}));

const StagingLabelMobile = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  fontSize: "14px",
}));

const NextPrevButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
    width: "100%",
  },
}));

const NextPrevButtonInnerBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginRight: "-14px",
  },
}));

const FooterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const DetailedViewBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 2,
  top: "105px",
  left: "139px",
  width: "127px",
  [theme.breakpoints.down("md")]: {
    top: "120px",
  },
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    top: "unset",
    bottom: "unset",
    left: "unset",
    marginTop: "20px",
  },
}));

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => {
  return {
    "& .MuiDataGrid-cell:focus": {
      outline: " none",
    },
    [`& .${gridClasses.cell}.Mui-selected`]: {
      backgroundColor: colors.red[50],
    },
    [`& .${gridClasses.cell}.Mui-selected:hover`]: {
      backgroundColor: colors.red[50],
    },
  };
});

const isGraphVisibleKey: string = "isGraphVisibleKey";
const selectionKey: string = "selection";

export default function MarketingData() {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(moment().startOf("month").format(DATE_FORMAT));
  const [endDate, setEndDate] = React.useState(moment().endOf("month").format(DATE_FORMAT));
  const [originalData, setOriginalData] = React.useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [statsVisibility, setStatsVisibility] = React.useState<StatsVisibility>({
    [CommonVariableNames.DetailedView]: false,
  });

  const [dataGrid, setDataGrid] = React.useState<DataGridState>({
    columns: [],
    rows: [],
    pinnedRows: { bottom: [] },
    scrollToIndexes: { rowIndex: 0, colIndex: 0 },
  });
  const [error, setError] = React.useState(false);
  const gridApiRef = useGridApiRef();
  const urlParams = useParams<{ publisherName: string; secret: string }>();
  const [graphProps, setGraphProps] = React.useState<{ date: string; graphType: string }>();
  const [isGraphVisible, setIsGraphVisible] = React.useState<boolean>(
    localStorage.getItem(isGraphVisibleKey) === null || localStorage.getItem(isGraphVisibleKey) === "true"
  );
  const [isLoadingFirstTime, setIsLoadingFirstTime] = React.useState<boolean>(false);
  const [loadedFirstTime, setLoadedFirstTime] = React.useState<boolean>(false);
  const [pinnedColRowsVisible, setPinnedColRowsVisible] = React.useState<boolean>(true);
  const secret = urlParams.secret || "";
  const publisherName = urlParams.publisherName || "";

  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (!didMount.current) {
      getMarketingDataAsync(startDate, endDate, true);
      didMount.current = true;
    }
  }, []);

  React.useEffect(() => {
    if (loadedFirstTime) {
      getMarketingDataAsync(startDate, endDate, false);
    }
  }, [startDate, endDate]);

  React.useLayoutEffect(() => {
    const updateSize = debounce(() => {
      if (window.innerWidth <= 600) {
        setPinnedColRowsVisible(false);
        if (gridApiRef.current) {
          gridApiRef.current.forceUpdate();
        }
      }

      if (window.innerWidth > 600) {
        setPinnedColRowsVisible(true);
        if (gridApiRef.current) {
          gridApiRef.current.forceUpdate();
        }
      }
    }, 500);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getMarketingDataAsync = async (startDate: string, endDate: string, firstLoad: boolean) => {
    try {
      firstLoad ? setIsLoadingFirstTime(true) : setIsLoading(true);
      const { data } = await getMarketingData(startDate, endDate, publisherName, secret);
      const formattedData = await formatData(data);
      setOriginalData(data);
      setDataGrid(formattedData as any);
      syncStatsVisibilityQueryParamsWithState();
      firstLoad ? setIsLoadingFirstTime(false) : setIsLoading(false);
      if (!loadedFirstTime) {
        setLoadedFirstTime(true);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      firstLoad ? setIsLoadingFirstTime(false) : setIsLoading(false);
    }
  };

  const renderLabelCell = (params: GridRenderCellParams) => {
    const { row } = params;

    if (row.visibility) {
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {isStaging() && (
            <Box display="flex" alignItems="center">
              <Tooltip
                enterTouchDelay={0}
                arrow
                title={
                  <Box>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>
                        <Typography variant="body2">
                          BEM Gross = search queries identified by Big Engage system
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Redirected = invalid searches detected & not sent to feed
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">Feed Gross = queries received by search feed</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">Max Cap = daily cap for feed</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Monetized = potential revenue generating queries identified by feed
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">Clicks = clicks on ads</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">RPM = revenue per 1000</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">Revenue = amount earned</Typography>
                      </li>
                    </ul>
                  </Box>
                }
                placement="left-start"
              >
                <HelpIcon sx={{ width: 20, height: 20, marginRight: 0.5 }} />
              </Tooltip>
              <Typography variant="body2">BEM Gross</Typography>
            </Box>
          )}
          {isStaging() && <Typography variant="body2">Redirected</Typography>}
          <Typography variant="body2">{isStaging() ? "Feed Gross" : "Gross"}</Typography>
          {isStaging() && <Typography variant="body2">Max Cap</Typography>}
          <Typography variant="body2">Monetized</Typography>
          <Typography variant="body2">Clicks</Typography>
          <Typography variant="body2" className={classes.rpm}>
            RPM
          </Typography>
          <Typography variant="body2" className={classes.revenue}>
            Revenue
          </Typography>
          {row.visibility.detailedView && (
            <>
              <Typography variant="body2">Click/Searches</Typography>
              <Typography variant="body2">Revenue/Click</Typography>
            </>
          )}
        </Box>
      );
    }
  };

  const getClickSearchesPercentage = (clicks: number, searches: number): string => {
    if (Number(clicks) === 0) {
      return "0";
    }
    return ((Number(clicks) / Number(searches)) * 100).toFixed(2);
  };

  const getRevenueClickRatio = (revenue: number, clicks: number): string => {
    if (Number(revenue) === 0) {
      return "0";
    }
    return (Number(revenue) / Number(clicks)).toFixed(2);
  };

  const handleClickStat = (date: string, graphType: string) => {
    setGraphProps({ date, graphType });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderDateCell = (params: GridRenderCellParams) => {
    const { row, colDef } = params;

    if (row[colDef.field]) {
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {isStaging() && <Typography variant="body2">0</Typography>}
          {isStaging() && (
            <Typography variant="body2">
              <a target="__blank" href="https://google.com">
                0
              </a>
            </Typography>
          )}
          <StatValue
            text={formatNumbersWithCommas(row[colDef.field][CommonVariableNames.TotalSearches])}
            isButton
            withLink={false}
            onClick={isGraphVisible ? () => handleClickStat(colDef.field, GraphTypes.Raw) : undefined}
          />
          {isStaging() && <Typography variant="body2">0</Typography>}
          <StatValue
            text={formatNumbersWithCommas(row[colDef.field][CommonVariableNames.MonetizedSearches])}
            isButton
            withLink={false}
            onClick={() => handleClickStat(colDef.field, GraphTypes.Monetized)}
          />
          <Typography variant="body2">{formatNumbersWithCommas(row[colDef.field].Clicks)}</Typography>
          <Typography variant="body2" className={classes.rpm}>
            ${formatNumbersWithCommas(row[colDef.field].RPM)}
            {row[colDef.field][CommonVariableNames.MonetizedSearches] === 0 ? "*" : ""}
          </Typography>
          <StatValue
            text={`$${formatNumbersWithCommas(row[colDef.field].Revenue)}`}
            isButton
            withLink={false}
            onClick={() => handleClickStat(colDef.field, GraphTypes.Rev)}
            customClass={classes.revenue}
          />
          {row.visibility.detailedView && (
            <>
              <Typography variant="body2">
                {getClickSearchesPercentage(
                  row[colDef.field].Clicks,
                  row[colDef.field][CommonVariableNames.MonetizedSearches] ||
                    row[colDef.field][CommonVariableNames.TotalSearches]
                )}
                %{row[colDef.field][CommonVariableNames.MonetizedSearches] === 0 ? "*" : ""}
              </Typography>
              <Typography variant="body2">
                ${getRevenueClickRatio(Number(row[colDef.field].Revenue), row[colDef.field].Clicks)}
              </Typography>
            </>
          )}
        </Box>
      );
    }
  };

  const renderTotalCell = (params: GridRenderCellParams) => {
    const { row } = params;
    if (row.total !== undefined) {
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {isStaging() && <Typography variant="body2">0</Typography>}
          {/* Link is placeholder for now */}
          {isStaging() && (
            <a target="__blank" href="https://google.com">
              0
            </a>
          )}
          <Typography variant="body2">{formatNumbersWithCommas(row.total.totalOfTotalSearches)}</Typography>
          {isStaging() && <Typography variant="body2">0</Typography>}
          <Typography variant="body2">{formatNumbersWithCommas(row.total.totalMonetizedSearches)}</Typography>
          <Typography variant="body2">{formatNumbersWithCommas(row.total.totalClicks)}</Typography>
          <Typography variant="body2" className={classes.rpm}>
            ${formatNumbersWithCommas(row.total.totalRpm)}
            {row.total.totalMonetizedSearches === 0 ? "*" : ""}
          </Typography>
          <Typography variant="body2" className={classes.revenue}>
            ${formatNumbersWithCommas(row.total.totalRevenue)}
          </Typography>
          {row.visibility.detailedView && (
            <>
              <Typography variant="body2">
                {getClickSearchesPercentage(row.total.totalClicks, row.total.totalMonetizedSearches)}%
              </Typography>
              <Typography variant="body2">
                ${getRevenueClickRatio(Number(row.total.totalRevenue), row.total.totalClicks)}
              </Typography>
            </>
          )}
        </Box>
      );
    }
  };

  const renderChannelCell = (params: GridRenderCellParams) => {
    if (params.row === undefined || Object.keys(params.row).length === 0) {
      return;
    }

    return (
      <Box>
        <ChannelLabel>{params.row.channel}</ChannelLabel>
        {isStaging() && (
          <>
            {params.row.channel !== "Overall" && params.row.hierarchy.length === 1 && (
              <ul style={{ padding: 0, paddingLeft: "13px", margin: 0, marginTop: "5px" }}>
                <li className={classes.rpm}>
                  <LinkTooltip url="https://scandiweb.com/blog/tooltip-best-practices/" />
                </li>
                <li>Status: active</li>
                <li>Feed: type-in</li>
                <li>Provider</li>
              </ul>
            )}
          </>
        )}
      </Box>
    );
  };

  const getTotalOfStatValues = (data: DataPoint[]) => {
    let clicks: number = 0;
    let monetizedSearches: number = 0;
    let totalSearches: number = 0;
    let revenue: number = 0;
    let rpm: number = 0;

    data.forEach((record) => {
      if (
        record.Clicks !== undefined &&
        record.Revenue !== undefined &&
        record.RPM !== undefined &&
        record[CommonVariableNames.TotalSearches] !== undefined &&
        record[CommonVariableNames.MonetizedSearches] !== undefined
      ) {
        clicks += Number(record[CommonVariableNames.Clicks]);
        revenue += Number(record[CommonVariableNames.Revenue]);
        rpm += Number(record[CommonVariableNames.RPM]);
        totalSearches += Number(record[CommonVariableNames.TotalSearches]);
        monetizedSearches += Number(record[CommonVariableNames.MonetizedSearches]);
      }
    });

    return { clicks, monetizedSearches, totalSearches, revenue, rpm };
  };

  // Arranging data to show in a grid
  const formatData = (data: DataPoint[]) => {
    return new Promise((resolve) => {
      const newDatesBetweenStartAndEnd = getDaysBetweenDates(
        moment(startDate, DATE_FORMAT),
        moment(endDate, DATE_FORMAT)
      );
      // Creating DataGrid Columns array
      const columns: GridColDef[] = [
        {
          field: "channel",
          headerName: "Channel",
          width: 150,
          align: "left",
          headerAlign: "left",
          sortable: false,
          hideable: false,
          renderCell: renderChannelCell,
          cellClassName: classes.colBackground,
          headerClassName: classes.headerBackground,
        },
        {
          field: "labels",
          headerName: "",
          width: 120,
          sortable: false,
          filterable: false,
          hideable: false,
          disableColumnMenu: true,
          renderCell: renderLabelCell,
          cellClassName: classes.colBackground,
          align: "right",
          headerClassName: classes.headerBackground,
        },
        ...newDatesBetweenStartAndEnd.map(
          (dateString): GridColDef => ({
            field: dateString,
            headerName: moment(dateString, DATE_FORMAT).format("MM-DD-YY"),
            width: 80,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderCell: renderDateCell,
            align: "right",
            headerAlign: "right",
          })
        ),
        {
          field: "total",
          headerName: "Total",
          width: 100,
          renderCell: renderTotalCell,
          disableColumnMenu: true,
          sortable: false,
          filterable: false,
          align: "right",
          headerAlign: "right",
          cellClassName: classes.colBackground,
          headerClassName: classes.headerBackground,
        },
      ];

      // Finding all channel names
      const allChannels: string[] = [];
      data.forEach((dataPoint) => {
        if (dataPoint.Channel.length !== 0 && allChannels.indexOf(dataPoint.Channel) === -1) {
          allChannels.push(dataPoint.Channel);
        }
      });

      allChannels.sort((a, b) => {
        const nameA = isNaN(Number(a)) ? a : Number(a);
        const nameB = isNaN(Number(b)) ? b : Number(b);

        if (typeof nameA === "number" && typeof nameB === "number") {
          return nameA - nameB;
        }

        if (typeof nameA === "number") return -1; // Put numbers before strings
        if (typeof nameB === "number") return 1;

        return nameA.localeCompare(nameB);
      });

      const rows: any[] = [];
      allChannels.forEach((channel) => {
        const allRecordsOfChannel = data.filter((dataPoint) => dataPoint.Channel === channel);

        // Finding all geos
        const allGeo: string[] = [];
        allRecordsOfChannel.forEach((dataPoint) => {
          if (allGeo.indexOf(dataPoint.GEO) === -1) {
            allGeo.push(dataPoint.GEO);
          }
        });

        let row: any = {};
        newDatesBetweenStartAndEnd.forEach((date) => {
          const recordsWithDate = allRecordsOfChannel.filter((record) => record.Date === date);

          const { clicks, monetizedSearches, totalSearches, revenue } = getTotalOfStatValues(recordsWithDate);

          if (recordsWithDate.length > 0) {
            row[date] = {
              Clicks: clicks,
              [CommonVariableNames.MonetizedSearches]: monetizedSearches,
              [CommonVariableNames.TotalSearches]: totalSearches,
              [CommonVariableNames.Revenue]: formatNumTo2Decimals(revenue),
              [CommonVariableNames.RPM]:
                allGeo.length > 1 ? calculateRpm(revenue, monetizedSearches || totalSearches) : recordsWithDate[0].RPM,
            };
            row.hierarchy = [channel];
            row.geography = recordsWithDate.length === 1 ? recordsWithDate[0].GEO : "";
            row.latestDateOnWhichDataPointIsAvailable = date;
          }
        });
        const rowObjValues = Object.values(row);
        const { clicks, monetizedSearches, totalSearches, revenue } = getTotalOfStatValues(rowObjValues as DataPoint[]);
        const totalRevenue = formatNumTo2Decimals(revenue);
        const totalRpm = calculateRpm(revenue, monetizedSearches || totalSearches);
        const totalClicks = clicks;
        const totalMonetizedSearches = monetizedSearches;
        const totalOfTotalSearches = totalSearches;
        row.total = { totalRevenue, totalRpm, totalClicks, totalMonetizedSearches, totalOfTotalSearches };
        row = { ...row, channel, hierarchy: [channel], visibility: getStatsVisibilityQueryParams() };
        rows.push(row);

        // Calculating each geography separately
        if (allGeo.length > 1) {
          allGeo.forEach((geo) => {
            let row: any = {};
            newDatesBetweenStartAndEnd.forEach((date) => {
              const recordsWithDate = allRecordsOfChannel.filter(
                (record) => record.Date === date && record.GEO === geo
              );

              if (recordsWithDate.length > 0) {
                row[date] = recordsWithDate[0];
                row.latestDateOnWhichDataPointIsAvailable = date;
              }
            });
            const rowObjValues = Object.values(row);
            const { clicks, monetizedSearches, totalSearches, revenue } = getTotalOfStatValues(
              rowObjValues as DataPoint[]
            );
            const totalRevenue = formatNumTo2Decimals(revenue);
            const totalRpm = calculateRpm(revenue, monetizedSearches || totalSearches);
            const totalClicks = clicks;
            const totalMonetizedSearches = monetizedSearches;
            const totalOfTotalSearches = totalSearches;
            row.total = { totalRevenue, totalRpm, totalClicks, totalMonetizedSearches, totalOfTotalSearches };
            row = {
              ...row,
              channel: "",
              geography: geo,
              hierarchy: [channel, `${channel}::${geo}`],
              visibility: getStatsVisibilityQueryParams(),
            };
            rows.push(row);
          });
        }
      });

      // Calculating overall row
      let overallRow = {
        channel: "Overall",
        total: {},
        hierarchy: [],
        visibility: getStatsVisibilityQueryParams(),
      };
      newDatesBetweenStartAndEnd.forEach((date) => {
        let totalRevenueColumn: number = 0;
        let totalMonetizedSearchesColumn = 0;
        let totalOfTotalSearchsColumn = 0;
        let totalClickColumn = 0;
        rows.forEach((row) => {
          if (row.hierarchy.length === 1 && row[date] !== undefined) {
            totalRevenueColumn += Number(row[date].Revenue);
            totalMonetizedSearchesColumn += Number(row[date][CommonVariableNames.MonetizedSearches]);
            totalOfTotalSearchsColumn += Number(row[date][CommonVariableNames.TotalSearches]);
            totalClickColumn += Number(row[date].Clicks);
          }
        });
        if (
          totalRevenueColumn === 0 &&
          totalMonetizedSearchesColumn === 0 &&
          totalOfTotalSearchsColumn === 0 &&
          totalClickColumn === 0
        ) {
        } else {
          overallRow[date] = {
            [CommonVariableNames.MonetizedSearches]: totalMonetizedSearchesColumn,
            [CommonVariableNames.TotalSearches]: totalOfTotalSearchsColumn,
            [CommonVariableNames.RPM]: calculateRpm(
              totalRevenueColumn,
              totalMonetizedSearchesColumn || totalOfTotalSearchsColumn
            ),
            [CommonVariableNames.Revenue]: totalRevenueColumn.toFixed(2),
            [CommonVariableNames.Clicks]: totalClickColumn,
          };
        }
      });
      // Calculating overall of total column
      let totalRevenueColumn: number = 0;
      let totalMonetizedSearchesColumn = 0;
      let totalOfTotalSearchsColumn = 0;
      let totalClickColumn = 0;
      rows.forEach((row) => {
        if (row.hierarchy.length === 1 && row.total !== undefined) {
          totalRevenueColumn += Number(row.total.totalRevenue);
          totalMonetizedSearchesColumn += Number(row.total.totalMonetizedSearches);
          totalOfTotalSearchsColumn += Number(row.total.totalOfTotalSearches);
          totalClickColumn += Number(row.total.totalClicks);
        }
      });

      overallRow.total = {
        totalMonetizedSearches: totalMonetizedSearchesColumn,
        totalOfTotalSearches: totalOfTotalSearchsColumn,
        totalRpm: calculateRpm(totalRevenueColumn, totalMonetizedSearchesColumn || totalOfTotalSearchsColumn),
        totalRevenue: totalRevenueColumn.toFixed(2),
        totalClicks: totalClickColumn,
      };

      // Finding RowIndex and ColIndex for scrolling to latest element
      let rowIndex = 0;
      let colIndex = 0;
      if (rows.length > 0) {
        let rowWhichHaveDataAvailableOnLatestDate = rows[0];
        rows.forEach((row, index) => {
          if (index === 0) {
            return;
          }

          if (
            moment(row.latestDateOnWhichDataPointIsAvailable, DATE_FORMAT).isAfter(
              moment(rowWhichHaveDataAvailableOnLatestDate.latestDateOnWhichDataPointIsAvailable, DATE_FORMAT)
            )
          ) {
            rowWhichHaveDataAvailableOnLatestDate = row;
            rowIndex = index;
          }
        });
        colIndex =
          Number(
            newDatesBetweenStartAndEnd.indexOf(
              rowWhichHaveDataAvailableOnLatestDate.latestDateOnWhichDataPointIsAvailable
            )
          ) + 3;
      }

      if (rows.length > 0) {
        setTimeout(() => {
          if (gridApiRef.current && gridApiRef.current.scrollToIndexes) {
            gridApiRef.current.scrollToIndexes({
              rowIndex,
              colIndex,
            });
          }
        }, 500);
      }

      const newRows = rows.map((row, index) => ({ ...row, id: `${index}-${row.hierarchy.join("")}` }));

      resolve({
        ...dataGrid,
        columns,
        rows: newRows,
        pinnedRows: { bottom: [{ ...overallRow, id: rows.length }] },
        scrollToIndexes: { rowIndex, colIndex },
      });
    });
  };

  const handleClickPrevMonth = () => {
    const newStartDate = moment(startDate, DATE_FORMAT).subtract(1, "month").format(DATE_FORMAT);
    const newEndDate = moment(newStartDate, DATE_FORMAT).endOf("month").format(DATE_FORMAT);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setGraphProps({
      date: newEndDate,
      graphType: GraphTypes.Raw,
    });
  };

  const handleClickNextMonth = () => {
    const newStartDate = moment(startDate, DATE_FORMAT).add(1, "month").format(DATE_FORMAT);
    const newEndDate = moment(newStartDate, DATE_FORMAT).endOf("month").format(DATE_FORMAT);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setGraphProps({
      date: newEndDate,
      graphType: GraphTypes.Raw,
    });
  };

  const isNextMonthButtonDisabled = () => {
    return moment().format("MMMM YYYY") === moment(startDate, DATE_FORMAT).format("MMMM YYYY");
  };

  const isPrevMonthButtonDisabled = () => {
    return moment("Jan 2023", "MMMM YYYY").format("MMMM YYYY") === moment(startDate, DATE_FORMAT).format("MMMM YYYY");
  };

  const toggleStatsVisibility = (event: React.SyntheticEvent<Element, Event>, checked: boolean, type: string) => {
    addStatsVisibilityQueryParams(type, checked);
    setStatsVisibility({
      ...statsVisibility,
      [type]: checked,
    });
    const newRows = dataGrid.rows.map((row) => ({ ...row, visibility: { ...statsVisibility, [type]: checked } }));
    const newPinnedRows = dataGrid.pinnedRows.bottom?.map((row) => ({
      ...row,
      visibility: { ...statsVisibility, [type]: checked },
    }));

    gridApiRef.current.setRows(newRows);

    setDataGrid({ ...dataGrid, pinnedRows: { bottom: newPinnedRows } });
  };

  const addStatsVisibilityQueryParams = (key: string, value: boolean) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(key)) {
      urlParams.set(key, value.toString());
    } else {
      urlParams.append(key, value.toString());
    }
    const queryString = urlParams.toString().length > 0 ? `?${urlParams.toString()}` : "/";
    window.history.replaceState(null, "", queryString);
  };

  const getStatsVisibilityQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      [CommonVariableNames.DetailedView]:
        urlParams.has(CommonVariableNames.DetailedView) && urlParams.get(CommonVariableNames.DetailedView) === "true",
    };
  };

  const syncStatsVisibilityQueryParamsWithState = () => {
    setStatsVisibility({
      ...getStatsVisibilityQueryParams(),
    });
  };

  const handleClickClearSelections = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(selectionKey);

    const queryString = urlParams.toString().length > 0 ? `?${urlParams.toString()}` : window.location.pathname;
    window.history.replaceState(null, "", queryString);
    gridApiRef.current.updateRows([]);
  };

  const StatVisibilitySwitch = ({ type, label }) => {
    return (
      <FormControlLabel
        sx={{ marginLeft: "1px", width: "100%" }}
        control={
          <Switch
            disabled={isLoading}
            size="small"
            checked={statsVisibility[type]}
            onChange={(event, checked) => toggleStatsVisibility(event, checked, type)}
            color="warning"
          />
        }
        label={<Typography variant="body2">{label}</Typography>}
        labelPlacement="start"
      />
    );
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.hierarchy;

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "Geo",
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
    align: "center",
    headerAlign: "center",
    width: 65,
    sortingOrder: ["asc", "desc"],
  };

  function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode, row } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps["onClick"] = (event) => {
      event.stopPropagation();

      if (rowNode.type !== "group") {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
    };

    return (
      <Box>
        <div>
          {filteredDescendantCount > 0 ? (
            <Button onClick={handleClick} tabIndex={-1} size="small" sx={{ color: "black" }}>
              ({filteredDescendantCount})
              {(rowNode as GridDataGroupNode).childrenExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          ) : (
            <Typography>{row.geography}</Typography>
          )}
        </div>
      </Box>
    );
  }

  const GridToolbar = () => {
    return (
      <Box padding="10px">
        <HeaderBox>
          <StagingLabel>
            {isStaging() && (
              <>
                Env: <b style={{ color: "red" }}>Staging</b>
              </>
            )}
          </StagingLabel>
          <Title>
            Stats {moment(startDate, DATE_FORMAT).format("MMMM YYYY")} for{" "}
            <span style={{ textTransform: "capitalize" }}>{publisherName}</span>
          </Title>
          <LifeTimeEarnings>{isStaging() && <>Lifetime earnings: $2000</>}</LifeTimeEarnings>
        </HeaderBox>
        <HeaderBoxMobile>
          <StagingLabelMobile>
            {isStaging() && (
              <>
                Env: <b style={{ color: "red" }}>Staging</b>
              </>
            )}
          </StagingLabelMobile>
          <LifeTimeEarningsMobile>{isStaging() && <>Lifetime earnings: $2000</>}</LifeTimeEarningsMobile>
        </HeaderBoxMobile>
        <NextPrevButtonBox>
          {!isGraphVisible ? (
            <FormControlLabel
              sx={{ marginRight: 1, marginLeft: 0 }}
              control={
                <Switch
                  size="small"
                  checked={isGraphVisible}
                  onChange={() => toggleGraphVisibility(!isGraphVisible)}
                  color="warning"
                />
              }
              label="Chart"
              labelPlacement="start"
            />
          ) : (
            <Box></Box>
          )}
          <NextPrevButtonInnerBox>
            {Object.keys(getSelectionObjFromUrl()).length > 0 && (
              <Button color="secondary" disabled={!originalData || isLoading} onClick={handleClickClearSelections}>
                Clear Selections
              </Button>
            )}
            <Button
              color="secondary"
              disabled={isPrevMonthButtonDisabled() || !originalData || isLoading}
              onClick={handleClickPrevMonth}
            >
              <ChevronLeftIcon />
              Prev month
            </Button>
            <Button
              color="secondary"
              onClick={handleClickNextMonth}
              disabled={isNextMonthButtonDisabled() || !originalData || isLoading}
            >
              <span>Next month</span>
              <ChevronRightIcon sx={{ paddingBottom: "2px" }} />
            </Button>
          </NextPrevButtonInnerBox>
        </NextPrevButtonBox>
        {originalData.length !== 0 && (
          <DetailedViewBox>
            <StatVisibilitySwitch type={CommonVariableNames.DetailedView} label="Detailed View" />
          </DetailedViewBox>
        )}
      </Box>
    );
  };

  const getRowClassName = (params) => {
    if (params.row === undefined || Object.keys(params.row).length === 0) {
      return "";
    }

    if (params.row.channel === "Overall" || params.row.hierarchy.length > 1) {
      return classes.colBackground;
    } else {
      return "";
    }
  };

  const getSelectionObjFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectionString = urlParams.get(selectionKey);

    if (selectionString) {
      try {
        const value = JSON.parse(selectionString as string);
        if (typeof value === "object") {
          return value;
        }
      } catch (error) {}
    }

    return {};
  };

  const getCellClassName = (params) => {
    const cellSelectionModel = getSelectionObjFromUrl();

    if (cellSelectionModel[params.id] !== undefined && cellSelectionModel[params.id][params.field] === true) {
      return classes.selectedCell;
    }

    if (params.field !== "channel" && params.field !== "labels" && params.field !== "total") {
      return classes.cellBorders;
    }
    return "";
  };

  const exportData = (data: any, fileName: string, type: string) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleClickExportCsv = () => {
    const csvData = Papa.unparse(originalData);
    exportData(
      csvData,
      `${publisherName}_${moment(startDate, DATE_FORMAT).format("MMMM_YYYY")}`,
      "text/csv;charset=utf-8;"
    );
  };

  const Footer = () => {
    return (
      <FooterBox>
        <Box display="flex" alignItems="center">
          <Typography fontWeight={500}>Links:</Typography>
          <Button color="secondary" onClick={handleClickExportCsv}>
            Download as CSV
          </Button>
        </Box>
        <Button
          color="secondary"
          target="_blank"
          href={`${process.env.REACT_APP_API_URL}/stats/${publisherName}/?key=${secret}&date_from=${startDate}&date_to=${endDate}`}
        >
          Link to your JSON API endpoint
        </Button>
        <Box display="flex" alignItems="center">
          <Typography fontWeight={500}>Email us:</Typography>
          <Button
            color="secondary"
            sx={{ textTransform: "lowercase", textDecoration: "underline" }}
            href="mailto:hello@bigengagemarketing.com"
          >
            hello@bigengagemarketing.com
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography fontWeight={500}>Skype us:</Typography>
          <Button
            color="secondary"
            sx={{ textTransform: "capitalize", textDecoration: "underline" }}
            href="skype:live:.cid.18d5ac8c366f96d3?chat"
          >
            Skype
          </Button>
        </Box>
      </FooterBox>
    );
  };

  const toggleGraphVisibility = (value: boolean) => {
    setIsGraphVisible(value);
    localStorage.setItem(isGraphVisibleKey, value.toString());
  };

  const handleCellClick = (params: any) => {
    let cellSelectionModel = getSelectionObjFromUrl();

    if (cellSelectionModel[params.id] !== undefined && cellSelectionModel[params.id][params.field] === true) {
      delete cellSelectionModel[params.id][params.field];
      if (Object.keys(cellSelectionModel[params.id]).length === 0) {
        delete cellSelectionModel[params.id];
      }
      // cellSelectionModel = {
      //   ...cellSelectionModel,
      //   [params.id]: {
      //     ...cellSelectionModel[params.id],
      //     [params.field]: false,
      //   },
      // };
    } else {
      cellSelectionModel = {
        ...cellSelectionModel,
        [params.id]: {
          ...cellSelectionModel[params.id],
          [params.field]: true,
        },
      };
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (Object.keys(cellSelectionModel).length === 0) {
      urlParams.delete(selectionKey);
    } else {
      const cellSelectionModelJsonStr = JSON.stringify(cellSelectionModel);

      if (urlParams.has(selectionKey)) {
        urlParams.set(selectionKey, cellSelectionModelJsonStr);
      } else {
        urlParams.append(selectionKey, cellSelectionModelJsonStr);
      }
    }
    const queryString = urlParams.toString().length > 0 ? `?${urlParams.toString()}` : window.location.pathname;
    window.history.replaceState(null, "", queryString);
    gridApiRef.current.updateRows([]);
  };

  const renderDatGrid = () => {
    let pinned;
    if (pinnedColRowsVisible) {
      pinned = { left: ["channel", "geo", "labels"], right: ["total"] };
    } else {
      pinned = null;
    }

    return (
      <StyledDataGridPro
        rowSelection={false}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: colors.lightBlue[50],
          },
          "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1px solid`,
            borderColor: isStaging() ? "#ffffff" : "#e0e0e0",
          },
          background: isStaging() ? colors.lightGreen[50] : "#ffffff",
          maxHeight: "100vh",
          height: dataGrid.rows.length === 0 ? "300px" : "unset",
        }}
        treeData={originalData.length !== 0}
        apiRef={gridApiRef}
        rows={dataGrid.rows}
        pinnedRows={originalData.length > 0 ? dataGrid.pinnedRows : {}}
        columns={dataGrid.columns}
        rowHeight={baseRowHeight}
        getRowHeight={() => {
          if (statsVisibility.detailedView) {
            return detailedViewRowHeight;
          } else {
            return baseRowHeight;
          }
        }}
        disableRowSelectionOnClick
        loading={isLoading}
        initialState={{
          pinnedColumns: pinned,
        }}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        onCellClick={handleCellClick}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        slots={{
          toolbar: () => <GridToolbar />,
          footer: () => <Footer />,
        }}
      />
    );
  };

  if (error) {
    return <NotFound />;
  }

  const baseRowHeight = isStaging() ? 180 : 120;
  const detailedViewRowHeight = isStaging() ? 220 : 160;
  return (
    <Box>
      <Header />
      {isGraphVisible && (
        <PublisherStatsGraph
          date={graphProps?.date}
          graphType={graphProps?.graphType}
          toggleGraphVisibility={toggleGraphVisibility}
          isGraphVisible={isGraphVisible}
        />
      )}
      {isLoadingFirstTime ? (
        <Box minHeight="300px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress />
          <Typography marginTop={1}>Loading Data Grid</Typography>
        </Box>
      ) : (
        <Box maxHeight="100vh">{renderDatGrid()}</Box>
      )}
    </Box>
  );
}
