import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import logo from "../../assets/logo.png";

const CustomAppBar = styled(AppBar)<AppBarProps>(() => ({
  backgroundColor: "#000000",
}));

function Header() {
  const Logo = ({ displayStyle }) => {
    return (
      <Box
        component="img"
        alt="logo"
        src={logo}
        sx={{
          mr: 2,
          display: displayStyle,
          height: "45px",
          width: "100px",
          marginLeft: "20px",
        }}
      />
    );
  };

  return (
    <CustomAppBar position="static">
      <Toolbar disableGutters>
        <Logo displayStyle={{ xs: "none", md: "flex" }} />
        <Logo displayStyle={{ xs: "flex", md: "none" }} />
      </Toolbar>
    </CustomAppBar>
  );
}

export default Header;
