// These variable names are needed to be used at more then one place
export enum CommonVariableNames {
  TotalRevenue = "totalRevenue",
  TotalRpm = "totalRpm",
  TotalClicks = "totalClicks",
  TotalMonetizedSearches = "totalMonetizedSearches",
  TotalOfTotalSearches = "totalOfTotalSearches",
  Revenue = "Revenue",
  RPM = "RPM",
  Clicks = "Clicks",
  MonetizedSearches = "Monetized Searches",
  TotalSearches = "Total Searches",
  DetailedView = "detailedView",
}

export type DataPoint = {
  Date: string;
  Channel: string;
  SubID: string;
  GEO: string;
  [CommonVariableNames.TotalSearches]: number;
  [CommonVariableNames.MonetizedSearches]: number;
  [CommonVariableNames.Clicks]: number;
  [CommonVariableNames.RPM]: string;
  [CommonVariableNames.Revenue]: string;
};

export type StatsVisibility = {
  [CommonVariableNames.DetailedView]: boolean;
};

export enum GraphTypes {
  Rev = "rev",
  Raw = "raw",
  Monetized = "monetized",
  Cap = "cap",
}

export enum GraphPeriod {
  Daily = "daily",
  Monthly = "monthly",
}
