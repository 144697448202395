export const calculateTotal = (array, property, formatTo2Decimals: boolean) => {
  let sum = 0;

  array.forEach((element) => {
    if (element && typeof element === "object" && element.hasOwnProperty(property)) {
      sum += Number(element[property]);
    }
  });

  return formatTo2Decimals ? formatNumTo2Decimals(sum) : sum;
};

export const getClickSearchesPercentage = (clicks: number, searches: number): string => {
  if (Number(clicks) === 0) {
    return "0";
  }
  return formatNumTo2Decimals((Number(clicks) / Number(searches)) * 100);
};

export const getRevenueClickRatio = (revenue: number, clicks: number): string => {
  if (Number(revenue) === 0) {
    return "0";
  }
  return formatNumTo2Decimals(Number(revenue) / Number(clicks));
};

export const formatNumbersWithCommas = (value: number | string) => {
  const typeOfValue = typeof value;
  const locale: string = "en-US";
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

  if (typeOfValue === "string") {
    const isDecimalExist = (value as string).split(".").length > 1;

    if (isDecimalExist) {
      return parseFloat(value as string).toLocaleString(locale, options);
    } else {
      return Number(value).toLocaleString(locale);
    }
  } else {
    return value.toLocaleString(locale);
  }
};

export const formatNumTo2Decimals = (value) => {
  return Number(value).toFixed(2);
};

export const getStatLabel = (key: string) => {
  const labels = {
    raw: "Gross Searches",
    rev: "Revenue",
    cap: "Cap",
    monetized: "Monetized searches",
  };

  return labels[key];
};

export const calculateRpm = (revenue: number, monetized: number) => {
  return formatNumTo2Decimals((revenue / monetized) * 1000);
};
