import PublisherChannelStats from "./pages/PublisherChannelStats";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { LicenseInfo } from "@mui/x-license-pro";
import { RoutePaths } from "./constants/routes";
import { SnackBarProvider } from "./contexts/Snackbar/snackbar.context";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import WrapperChannelStats from "./pages/WrapperChannelStats";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY || "");

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <SnackBarProvider>
          <BrowserRouter>
            <Routes>
              <Route path={RoutePaths.PublisherStats} Component={PublisherChannelStats} />
              <Route path={RoutePaths.WrapperStats} Component={WrapperChannelStats} />
              <Route path={RoutePaths.PageNotFound} Component={NotFound} />
            </Routes>
          </BrowserRouter>
        </SnackBarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
