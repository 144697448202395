import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

export default function NotFound() {
  return (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h3" color={red[700]}>
        Page not found 404
      </Typography>
    </Box>
  );
}
