import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "dark",
    },
    warning: {
      main: "#ffc00b",
    },
  },
});
